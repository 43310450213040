// extracted by mini-css-extract-plugin
export var TextAndLinks = "q_9";
export var linkGroup = "q_bb";
export var linkGroup_heading = "q_bc";
export var links = "q_bd";
export var links__item = "q_bf";
export var link = "q_bg";
export var textContent = "q_bh";
export var defaultTextStyle = "q_bj";
export var largeTextStyle = "q_bk";
export var aniSpin = "q_d";